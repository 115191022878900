import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { NgbDropdownModule, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MomentModule } from 'ngx-moment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BryntumGridModule } from '@bryntum/grid-angular';

import { AppComponent } from './app.component';
import { DatetimePickerComponent } from './components/shared/datetime-picker/datetime-picker.component';
import { HelpIconComponent } from './components/shared/help-icon/help-icon.component';
import { DatepickerMonthYearComponent } from './components/shared/month-year/datepicker-month-year';
import { DatepickerYearComponent } from './components/shared/only-year/datepicker-year';
import { MaterialModule } from './modules/material/material.module';
import { ClipboardModule } from 'ngx-clipboard';
import { CustomInputDialogComponent } from './components/shared/custom-input-dialog/custom-input-dialog.component';
import { DxSelectBoxModule, DxTextAreaModule, DxFormModule, DxNumberBoxModule, DxCheckBoxModule, DxButtonModule } from 'devextreme-angular';
import { DevExtremeModule } from 'devextreme-angular';
import { ToastrModule } from 'ngx-toastr';
// import { PlaidAuthComponent } from './components/applications/plaid-auth/plaid-auth.component';
import { PagenotfoundComponent } from './components/shared/pagenotfound/pagenotfound.component';
import { TransactionFormLayoutComponent } from './components/shared/transaction-form-layout/transaction-form-layout.component';
import { StatusBadgeComponent } from './components/shared/status-badge/status-badge.component';
import { MonthlyReviewBaseLayoutComponent } from './components/shared/monthly-review-base-layout/monthly-review-base-layout.component';
import { BaseComponent } from './components/shared/base/base.component';
import { FilePreviewComponent } from './components/shared/file-preview/file-preview.component';
import { PerformanceManagementTxnFormLayoutComponent } from './components/shared/performance-management-txn-form-layout/performance-management-txn-form-layout.component';
import { S3FileService } from './services/s3file-service';
import { FileAcknowledgeComponent } from './components/shared/file-acknowledge/file-acknowledge.component';
import { CustomClientDialogComponent } from './components/shared/custom-client-dialog/custom-client-dialog.component';
import { LocationService } from './services/location.service';
import { AddNotificationComponent } from './components/shared/add-notification/add-notification.component';
import { UserGroupAddDialogComponent } from './components/shared/user-group-add-dialog/user-group-add-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { BaseAppModule } from './modules/base/base.app.module';
import { PlaidAuthComponent } from './components/applications/plaid-auth/plaid-auth.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ImageCarouselComponent } from './components/shared/image-carousel/image-carousel.component';
import { PostDatePipe } from './components/shared/post-date/post-date.pipe';
import { GsConnectPostNotificationMessagePipe } from './components/shared/gs-connect-post-notification-message/gs-connect-post-notification-message.pipe';

const modules = [
	FormsModule,
	ReactiveFormsModule,
	MaterialModule,
	NgbDropdownModule,
	NgbDatepickerModule,
	NgbTimepickerModule,
	NgxSpinnerModule,
	MomentDateModule,
	MomentModule,
	ClipboardModule,
	BryntumGridModule,
	BaseAppModule,
];

const components = [
	DatepickerYearComponent,
	HelpIconComponent,
	DatetimePickerComponent,
	DatepickerMonthYearComponent,
	CustomInputDialogComponent,
	PlaidAuthComponent,
	FilePreviewComponent,
	FileAcknowledgeComponent,
];

const translate: Type<any> = TranslateModule.forRoot({
	loader: {
		provide: TranslateLoader,
		useFactory: httpTranslateLoader,
		deps: [HttpClient],
	},
}) as any;

@NgModule({
	imports: [
		CommonModule,
		DevExtremeModule,
		DxSelectBoxModule,
		DxTextAreaModule,
		DxFormModule,
		DxNumberBoxModule,
		DxCheckBoxModule,
		DxButtonModule,
		MatAutocompleteModule,
		...modules,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient],
			},
		}),
		ToastrModule.forRoot({ positionClass: 'inline' }),
		MatGoogleMapsAutocompleteModule,
		ImageCarouselComponent,
		PostDatePipe,
		GsConnectPostNotificationMessagePipe,
	],
	declarations: [
		...components,
		TransactionFormLayoutComponent,
		PagenotfoundComponent,
		StatusBadgeComponent,
		MonthlyReviewBaseLayoutComponent,
		BaseComponent,
		PerformanceManagementTxnFormLayoutComponent,
		FileAcknowledgeComponent,
		CustomClientDialogComponent,
		AddNotificationComponent,
		UserGroupAddDialogComponent,
	],

	exports: [
		...modules,
		...components,
		translate,
		TransactionFormLayoutComponent,
		MonthlyReviewBaseLayoutComponent,
		PerformanceManagementTxnFormLayoutComponent,
		AddNotificationComponent,
		UserGroupAddDialogComponent,
		ImageCarouselComponent,
		PostDatePipe,
		GsConnectPostNotificationMessagePipe,
	],
	providers: [S3FileService, LocationService, provideEnvironmentNgxMask()],
	bootstrap: [AppComponent],
})
export class ShareModule {}

export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
