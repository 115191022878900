<br />
<br />
<br />
<br />

<div style="margin: 15px">
	<app-loader class="appLoader"></app-loader>
	<h3>Applications</h3>
	<br />
	<!-- <a [routerLink]="['/commercialloan/1']">Commercial Loan Application - Line of Credit</a><br />
	<a [routerLink]="['/commercialloan/2']">Commercial Loan Application - Construction</a><br />
	<a [routerLink]="['/commercialloan/3']">Commercial Loan Application - Refinance</a><br />
	<a [routerLink]="['/commercialloan/4']">Commercial Loan Application - Purchase</a><br />
	<a [routerLink]="['/commercialloan/5']">Commercial Loan Application - Other</a><br />

	<br />

	<a [routerLink]="['/businesscreditcard']">Business Credit Card</a><br />

	<br />

	<a [routerLink]="['/personalcheckingaccount']">Personal Checking Account</a><br />

	<br />

	<a [routerLink]="['/personalcreditcard']" [queryParams]="{ step: 1, appId: '' }">Personal Credit Card</a><br />
	<br />

	<a [routerLink]="['/debitcardaccount']">Debit Card</a><br />
	<br />

	<a [routerLink]="['/interestcheckingaccount']">Interest Checking</a><br />
	<br />

	<a [routerLink]="['/savingsaccount']">Savings Account</a><br />
	<br />

	<a [routerLink]="['/moneymarketaccount']">Money Market</a><br />
	<br />

	<a [routerLink]="['/texasfreedomaccount']">Texas Freedom</a><br />
	<br />

	<a [routerLink]="['/personalhealthcareaccount']">Personal Health Care</a><br />
	<br />

	<a [routerLink]="['/hometownheroaccount']">Hometown Hero</a><br />
	<br />

	<a [routerLink]="['/highyieldmoneymarketaccount']">High Yield Money Market</a><br />
	<br /> -->
	<a [routerLink]="['/insuranceApplication/home']">Insurance Application</a>
	<br />
	<br />
	<a [routerLink]="['/accountopening/dashboard']">Account Opening</a><br />
	<br />
	<a [routerLink]="['/debitcardppplication/dashboard']">Debit Card Application</a><br />
	<br />
	<a [routerLink]="['/sendmessage']">Send Message</a><br />
	<br />

	<a [routerLink]="['/schedule']">Schedule an Appointment</a><br />
	<!-- 
    <a [routerLink]="['/creditcardapplication']">Credit Card Application</a><br>
    <br> -->
</div>
